import {cloneDeep} from "lodash"

export const transformOrder = {
    methods: {
        transformOrder(options) {

            // Create a deep clone so we can alter it without changing the original object.
            let order = cloneDeep(this.$store.state.order)

            // Set formatted wedding date.
            order.generalData.wed_date = this.$store.getters.getWeddingDateFormatted

            /*
            * Custom Covers
            * If we're using custom covers, copy the relevant uploaded image paths from our internal data structure to
            * the structure required for the submitted JSON. We're using an internal structure here because it allows us
            * to more easily use a centralised component for all image upload fields throughout the app.
            *
            * Theme
            * If we're not using custom covers just copy the theme to the relevant property value in the order object.
            * */
            if (this.$store.state.customCovers) {
                order.pages[0].data.img_fc = order.pages[0].data.img
                order.pages[0].data.img_bc = order.pages[3].data.img
            } else {
                order.pages[0].data.design = this.$store.state.theme
            }

            /*
            * Process questions & answers in Challenges B & L.
            * "The Happy Couple" & "Popping the Question".
            * */
            function processActivityQAndA(activityIndex) {
                /*
                * Process an activity's question and answer data.
                * This takes the values stored in our own internal data structure (data.questionsAndAnswers) and copies
                * them to the data structure required for the submitted JSON (data.q1, data.a1 etc).
                * E.g.
                * data.questionsAndAnswers[0].question
                * is copied to
                * data.q1
                * */
                const activity = order.activities[activityIndex]
                activity.data.questionsAndAnswers.forEach((qAndA, index) => {
                    activity.data["q" + String(index+1)] = qAndA.question
                    activity.data["a" + String(index+1)] = qAndA.answer
                })
            }
            processActivityQAndA(1)
            processActivityQAndA(11)

            /*
            * Process statements in Challenge J "True of False".
            * Copies data to a temporary array of statement objects, marking each statement as true of false.
            * Randomises the order of the temporary array (mixing true and false statements).
            * Copies relevant data to the structure required for the submitted JSON.
            * */
            let challengeJStatementsTemp = []
            // Mark facts as true and push to temp array.
            order.activities[9].data.statements.facts.forEach(fact => {
                fact.answer = "True"
                challengeJStatementsTemp.push(fact)
            })
            // Mark falsehoods as false and push to temp array.
            order.activities[9].data.statements.falsehoods.forEach(falsehood => {
                falsehood.answer = "False"
                challengeJStatementsTemp.push(falsehood)
            })
            // Randomise the order of the statements.
            challengeJStatementsTemp.sort( () => .5 - Math.random() )
            // Copy values to expected q*/a* properties.
            challengeJStatementsTemp.forEach((statement, index) => {
                order.activities[9].data["q" + String(index+1)] = statement.text
                order.activities[9].data["a" + String(index+1)] = statement.answer
            })

            /*
            * Process anagram data in Challenge M "Where Were We".
            * Copies data from our internal structure (data.anagrams) to the structure required for the submitted JSON
            * (data.a1, data.anag1 etc).
            * E.g.
            * data.anagrams[0].generated
            * is copied to
            * data.anag1
            * */
            order.activities[12].data.anagrams.forEach((anagram, index) => {
                const activity = order.activities[12]
                activity.data["a" + String(index+1)] = anagram.answer
                activity.data["anag" + String(index+1)] = anagram.generated
                activity.data["chcount" + String(index+1)] = anagram.charCount
                activity.data["h" + String(index+1)] = anagram.hint
            })

            // Remove internal-use-only properties to prevent 500 errors coming back
            // from the Pureprint API when it receives properties it's not expecting.
            // Back cover page object.
            order.pages.splice(3, 1)
            // Various activity properties.
            delete order.activities[1].data.questionsAndAnswers
            delete order.activities[9].data.statements
            delete order.activities[11].data.questionsAndAnswers
            delete order.activities[12].data.anagrams

            // Establish order of challenges.
            const pageChallengeMap = this.$store.state.pageChallengeMap
            let challengeOrder = []
            for (const key in pageChallengeMap) {
                if (options.absentFriends) {
                    challengeOrder.push(pageChallengeMap[key])
                } else {
                    // Filter out absent friends challenges from challengeOrder
                    // if we're transforming a regular, non-AF order.
                    if (key !== "absentFriendsH" && key !== "absentFriendsI") {
                        challengeOrder.push(pageChallengeMap[key])
                    }
                }
            }

            // Filter activities to just those that have been mapped to pages (preserve page order).
            let activitiesMapped = []
            challengeOrder.forEach(challengeItem => {
                const requiredActivity = order.activities.find(activity => activity.name === challengeItem)
                if (typeof requiredActivity !== "undefined") {
                    activitiesMapped.push(requiredActivity)
                }
            })

            // Filter out any activities that aren't marked as valid.
            order.activities = activitiesMapped.filter(activity => activity.valid)

            /*
            * Absent Friends
            * Replace certain values with AF equivalents.
            * */
            if (options.absentFriends) {
                console.log("AF enabled, swapping out data as required...")
                // Page 2
                order.pages[1].data.wed_intro = order.pages[1].data.abs_intro
                // Page 11
                order.pages[2].data.farewell = order.pages[2].data.abs_intro

                // Replace activities H & I if present.

                let activityHIndex = -1
                let activityIIndex = -1
                order.activities.find((activity, index) => {
                    if (activity.name === "h") {
                        activityHIndex = index
                    }
                    if (activity.name === "i") {
                        activityIIndex = index
                    }
                })

                const activityHReplacementId = this.$store.state.pageChallengeMap.absentFriendsH
                const activityIReplacementId = this.$store.state.pageChallengeMap.absentFriendsI

                if (activityHReplacementId !== "") {
                    let activityHReplacementIndex
                    order.activities.find((activity, index) => {
                        if (activity.name === activityHReplacementId) {
                            activityHReplacementIndex = index
                        }
                    })
                    const activityHReplacementObj = order.activities[activityHReplacementIndex]
                    order.activities.splice(activityHIndex, 1, activityHReplacementObj)
                    order.activities.splice(activityHReplacementIndex, 1)
                }

                if (activityIReplacementId !== "") {
                    let activityIReplacementIndex
                    order.activities.find((activity, index) => {
                        if (activity.name === activityIReplacementId) {
                            activityIReplacementIndex = index
                        }
                    })
                    const activityIReplacementObj = order.activities[activityIReplacementIndex]
                    order.activities.splice(activityIIndex, 1, activityIReplacementObj)
                    order.activities.splice(activityIReplacementIndex, 1)
                }

            }

            console.log("Transformed order:")
            console.log(order)

            return order

        },
    }
}
