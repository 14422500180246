import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from "firebase";

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    scrollBehavior () {
        // Always scroll to the top of the page.
        // See: https://router.vuejs.org/guide/advanced/scroll-behavior.html
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/',
            redirect: {name: 'Home'},
        },
        {
            path: '/home',
            name: 'Home',
            component: () => import('@/views/Home.vue'),
            meta: {
                pageId: "home"
            }
        },
        {
            path: '/login',
            name: 'Login',
            component: () => import('@/views/Login.vue'),
        },
        {
            path: '/reset-password',
            name: 'ResetPassword',
            component: () => import('@/views/ResetPassword.vue'),
        },
        {
            path: '/order',
            name: 'Order',
            component: () => import('@/views/Order.vue'),
            meta: {
                pageId: "order"
            }
        },
        {
            path: '/submit',
            name: 'Submit',
            component: () => import('@/views/Submit.vue'),
            meta: {
                pageId: "submit"
            }
        },
        {
            path: '/getting-started',
            name: 'GettingStarted',
            component: () => import('@/views/GettingStarted.vue'),
            meta: {
                pageId: "gettingStarted"
            }
        },
        {
            path: '/theme',
            name: 'Theme',
            component: () => import('@/views/Theme.vue'),
            meta: {
                pageId: "theme"
            }
        },
        {
            path: '/front-cover',
            name: 'Front',
            component: () => import('@/views/Front.vue'),
            meta: {
                pageId: "frontCover"
            }
        },
        {
            path: '/page-2',
            name: 'Page2',
            component: () => import('@/views/Page2.vue'),
            meta: {
                pageId: "insideFrontCover"
            }
        },
        {
            path: '/page-3',
            name: 'Page3',
            component: () => import('@/views/ChallengePage.vue'),
            meta: {
                pageId: "page3"
            }
        },
        {
            path: '/page-4',
            name: 'Page4',
            component: () => import('@/views/ChallengePage.vue'),
            meta: {
                pageId: "page4"
            }
        },
        {
            path: '/page-5',
            name: 'Page5',
            component: () => import('@/views/ChallengePage.vue'),
            meta: {
                pageId: "page5"
            }
        },
        {
            path: '/page-6-7',
            name: 'Page6-7',
            component: () => import('@/views/Page6-7.vue'),
            meta: {
                pageId: "page6-7"
            }
        },
        {
            path: '/page-8',
            name: 'Page8',
            component: () => import('@/views/ChallengePage.vue'),
            meta: {
                pageId: "page8"
            }
        },
        {
            path: '/page-9',
            name: 'Page9',
            component: () => import('@/views/ChallengePage.vue'),
            meta: {
                pageId: "page9"
            }
        },
        {
            path: '/page-10',
            name: 'Page10',
            component: () => import('@/views/ChallengePage.vue'),
            meta: {
                pageId: "page10"
            }
        },
        {
            path: '/page-11',
            name: 'Page11',
            component: () => import('@/views/Page11.vue'),
            meta: {
                pageId: "insideBackCover"
            }
        },
        {
            path: '/absent-friends',
            name: 'AbsentFriends',
            component: () => import('@/views/AbsentFriends.vue'),
            meta: {
                pageId: "absentFriends"
            }
        },
        {
            path: '/back-cover',
            name: 'Back',
            component: () => import('@/views/Back.vue'),
            meta: {
                pageId: "backCover"
            }
        },
        {
            path: '/help',
            name: 'Help',
            component: () => import('@/views/Help.vue'),
            meta: {
                pageId: "help"
            }
        },
        {
            path: '/wedding-wall',
            name: 'WeddingWall',
            component: () => import('@/views/WeddingWall.vue'),
            meta: {
                pageId: "weddingWall"
            }
        },
    ]
})

router.beforeEach((to, from, next) => {
    firebase.auth().onAuthStateChanged((user) => {
        if (user) {
            // Logged in.
            if (to.name === "Login" ||
                to.name === "ResetPassword"
            ) {
                next({
                    name: "Home",
                    query: to.query,
                })
            } else {
                next()
            }
        } else {
            // Logged out.
            if (to.name === "Login" ||
                to.name === "ResetPassword"
            ) {
                next()
            } else {
                next({
                    name: "Login",
                    query: to.query,
                })
            }
        }
    });
})

// Handle navigation duplication for router push (Globally)
// Credit: https://stackoverflow.com/a/65880001
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((error) => {
    });
};

export default router
