import firebase from "firebase";

export const firestore = {
    methods: {
        getAppStateFromFirestore() {
            // Update app state based on what's already been written to Firestore.
            console.log("Attempting to get app state from Firestore...")
            firebase.firestore().collection('pureprintOrders').doc(this.$store.state.pureprintOrderId).get().then((doc) => {
                // Note the data() method. This is required to get the data back in the expected format,
                // rather than a bunch of Firestore internal gubbins.
                // Check if cmsDataDump is empty.
                if (!("pageChallengeMap" in doc.data().cmsDataDump)) {
                    // If cmsDataDump is empty, initialise the CMS and write empty app state structure to Firebase.
                    console.log("Initialising app state...")
                    this.$store.commit("resetCmsDataDump")
                    this.$store.commit("setSourceOrderID", doc.data().orderRef)
                    this.$store.commit("setAbsentFriends", doc.data().absentFriends)
                    this.$store.commit("setCustomCovers", doc.data().customCovers)
                    this.$store.commit("setWeddingWallPin", doc.data().weddingWallPin)
                    this.$store.commit("setWeddingWallSlug", doc.data().weddingWallSlug)
                    console.log("Done.")
                    this.updateAppStateInFirestore()
                } else {
                    // If cmsDataDump is not empty, sync app state from Firebase.
                    console.log("Syncing app state from Firestore...")
                    this.$store.commit("setAppState", doc.data().cmsDataDump)
                    this.$store.commit("setAbsentFriends", doc.data().absentFriends)
                    this.$store.commit("setCustomCovers", doc.data().customCovers)
                    this.$store.commit("setWeddingDate", doc.data().weddingDate)
                    this.$store.commit("setWeddingWallPin", doc.data().weddingWallPin)
                    this.$store.commit("setWeddingWallSlug", doc.data().weddingWallSlug)
                    console.log("Done.")
                }
            }).catch((error) => {
                console.log(error)
            })
        },
        updateAppStateInFirestore() {
            // Write app state to Firestore.
            console.log("Attempting to update app state in Firestore...")
            firebase.firestore().collection('pureprintOrders').doc(this.$store.state.pureprintOrderId).update({
                cmsDataDump: {
                    marketingData: this.$store.state.marketingData,
                    order: this.$store.state.order,
                    pageChallengeMap: this.$store.state.pageChallengeMap,
                    pages: this.$store.state.pages,
                    readOnlyMode: this.$store.state.readOnlyMode,
                    submittedOrder: this.$store.state.submittedOrder,
                    theme: this.$store.state.theme,
                    weddingWall: this.$store.state.weddingWall,
                },
            }).then(() => {
                console.log("Done.")
            }).catch((error) => {
                console.log(error)
            })
        },
        resetDocumentInFirestore() {
            // Dev use only.
            // Resets the Firestore pureprintOrders document to its initial state and then refreshes the page.
            console.log("Attempting to reset document in Firestore...")
            firebase.firestore().collection('pureprintOrders').doc(this.$store.state.pureprintOrderId).update({
                        cmsDataDump: {},
                    }
            ).then(() => {
                console.log("Done.")
                window.location.reload(true)
            }).catch((error) => {
                console.log(error)
            })
        },
        getOrder() {
            // Check if the current auth user is an admin and whether the `order` query param is present.
            let loadOrderFromQueryParam = false
            if (this.$store.state.user.isAdmin &&
                this.$route.query.hasOwnProperty('order')) {
                loadOrderFromQueryParam = true
            }
            if (loadOrderFromQueryParam) {
                // Load the order specified in the query param.
                this.getSpecificOrder(this.$route.query.order)
            } else {
                // Load this user's latest order.
                this.getCurrentUserOrder()
            }
        },
        getSpecificOrder(ppOrderId) {
            console.log("Getting specific order ID from Firestore...")
            console.log("pureprint order:", ppOrderId)
            firebase.firestore().collection('pureprintOrders').doc(ppOrderId).get().then((doc) => {
                if (doc.exists) {
                    this.pureprintOrderId = ppOrderId
                    this.$store.commit("setPurePrintOrderId", ppOrderId)
                    this.$store.commit("setWeddingDate", doc.data().weddingDate)
                    this.getAppStateFromFirestore()
                    this.$store.commit("setSpecificOrderLoaded", true)
                } else {
                    // TODO: Make it clearer to the user that something went wrong.
                    console.error("Document doesn't exist")
                    this.$store.commit("setSpecificOrderLoaded", false)
                }
            }).catch((error) => {
                console.log(error)
            })
        },
        getCurrentUserOrder() {
            console.log("Getting latest order ID for current user from Firestore...")
            firebase.firestore().collection("pureprintOrders")
                    .where("uid", "==", firebase.auth().currentUser.uid)
                    .get()
                    .then(querySnapshot => {
                        querySnapshot.forEach(doc => {
                            // console.log("doc.id:", doc.id)
                            // console.log("doc.data():", doc.data())
                            // Store pureprint order id.
                            this.pureprintOrderId = doc.id
                            this.$store.commit("setPurePrintOrderId", doc.id)
                            this.$store.commit("setWeddingDate", doc.data().weddingDate)
                            this.$store.commit("setSpecificOrderLoaded", false)
                        })
                        this.getAppStateFromFirestore()
                    })
                    .catch(function(error) {
                        console.log("Error getting documents: ", error);
                    });
        },
        getUserDoc(uid) {
            console.log("Getting user doc from Firestore...")
            firebase.firestore().collection('users').doc(uid).get().then((doc) => {
                this.$store.commit("setUser", doc.data())
                this.getOrder()
            }).catch((error) => {
                console.log(error)
            })
        }
    },
}

export const auth = {
    methods: {
        logout() {
            firebase.auth().signOut().then(() => {
                firebase.auth().onAuthStateChanged(() => {
                    this.user = null
                    // Navigation guards should take take of redirecting
                    // the user to an appropriate page once logged out.
                })
            }).catch(function (error) {
                // An error happened.
                console.log(error);
            });
        }
    }
}
