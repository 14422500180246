<template>
  <v-app-bar
      app
      color="#d5c0ac"
      dark
      shrink-on-scroll
      height="170"
      src="@/assets/img/20201006-DSC_2098-1.jpg"
      prominent
      extension-height=25
  >
    <template v-slot:img="{ props }">
      <v-img
          v-bind="props"
          gradient="to top right, rgba(227,216,200,.4), rgba(213,192,172,.8)"
      ></v-img>
    </template>

    <v-app-bar-nav-icon @click="toggleNavDrawer">
      <div class="custom-hamburger">
        <v-icon color="#d5c0ac">mdi-menu</v-icon>
      </div>
    </v-app-bar-nav-icon>

    <v-toolbar-title><h6>CherryTop Weddings</h6></v-toolbar-title>

    <v-spacer></v-spacer>

    <v-btn
        :to="{name: 'Help'}"
        color="secondary"
        class="mt-1"
    >
      Help & FAQ
    </v-btn>

    <v-menu
        left
        bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            icon
            v-bind="attrs"
            v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="logout">
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
        <v-list-item
            @click="resetDocumentInFirestore"
            v-if="$store.state.user.isAdmin"
        >
          <v-list-item-title>Reset CMS data in Firebase</v-list-item-title>
        </v-list-item>
<!--        <v-list-item @click="transformOrder">-->
<!--          <v-list-item-title>Transform order</v-list-item-title>-->
<!--        </v-list-item>-->
      </v-list>
    </v-menu>

    <template v-slot:extension>
      <v-progress-linear
          absolute
          :value="completionProgress"
          color="green"
          height="25"
      >
        <template v-slot:default="{ value }">
          <span class="overline">{{ Math.ceil(value) }}% complete</span>
        </template>
      </v-progress-linear>
    </template>

  </v-app-bar>
</template>

<script>
import {auth} from "@/mixins/firebase";
import {firestore} from "@/mixins/firebase";
import {transformOrder} from "@/mixins/order";

export default {
  name: "AppBar",
  mixins: [
    auth,
    firestore,
    transformOrder,
  ],
  methods: {
    toggleNavDrawer() {
      this.$store.commit("toggleNavDrawer")
    }
  },
  computed: {
    completionProgress() {
      const absentFriends = this.$store.state.absentFriends
      const customCovers = this.$store.state.customCovers
      const pages = this.$store.state.pages
      const pagesToComplete = pages.filter(page => {
        if (page.complete !== null) {
          // Rules to exclude certain pages from the list of pages to complete
          // (for the purposes of calculating a completion percentage).
          if (page.id === "page6-7") {
            return false
          }
          if (!absentFriends && page.id === "absentFriends") {
            return false
          }
          if (customCovers) {
            if (page.id === "theme") {
              return false
            }
          } else {
            if (page.id === "frontCover" || page.id === "backCover") {
              return false
            }
          }
          return true
        }
        return false
      })
      const completedPages = pagesToComplete.filter(page => {
        return page.complete
      })
      return (completedPages.length / pagesToComplete.length) * 100
    },
    envIsNotProd() {
      return process.env.VUE_APP_ENVIRONMENT !== "production"
    }
  }
}
</script>

<style lang="scss">
  .custom-hamburger {
    background-color: white;
    padding: 6px;
    border-radius: 100%;
    &:hover {
      background-color: lighten(#d5c0ac, 20%)
    }
  }
</style>
