<template>
  <v-app id="inspire">
    <nav-drawer
        v-if="user"
        :key="pagesKey"
    />
    <app-bar v-if="user"/>
    <v-main class="grey lighten-5">
      <v-alert
          v-if="$store.state.specificOrderLoaded"
          type="info"
          rounded="0"
      >
        You are editing: Order: <pre class="d-inline font-weight-bold">{{ $store.state.order.sourceOrderId }}</pre> / PurePrint order: <pre class="d-inline font-weight-bold">{{ $store.state.pureprintOrderId }}</pre>
      </v-alert>
      <router-view></router-view>
      <nav-prev-next
          v-if="showNavPrevNext"
          :key="pagesKey"
      />
    </v-main>
    <Footer
        v-if="user"
    />
    <confirm ref="confirm"></confirm>
  </v-app>
</template>

<script>
import {auth} from "@/mixins/firebase";
import {firestore} from "@/mixins/firebase";
import Confirm from "@/components/Dialogs/Confirm";
import Footer from "@/components/Footer.vue"
import firebase from "firebase";
import NavDrawer from "@/components/NavDrawer";
import AppBar from "@/components/AppBar";
import NavPrevNext from "@/components/NavPrevNext";

export default {
  components: {
    AppBar,
    Confirm,
    Footer,
    NavDrawer,
    NavPrevNext
  },
  mixins: [
    auth,
    firestore
  ],
  created() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.user = user
        this.getUserDoc(user.uid)
      } else {
        this.user = null
      }
    })
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirm.open
    if (process.env.VUE_APP_ENVIRONMENT !== "production") {
      console.log("Environment:", process.env.VUE_APP_ENVIRONMENT)
      console.log("Firebase:", process.env.VUE_APP_FIREBASE_PROJECTID)
    }
  },
  data: () => ({
    user: null,
  }),
  computed: {
    pagesKey() {
      let key = ""
      this.$store.state.pages.forEach(page => {
        key += String(page.enabled)
      })
      return key
    },
    showNavPrevNext() {
      if (!this.user) {
        return false
      }
      if (this.$route.name === "Help") {
        return false
      }
      return true
    }
  },
}
</script>

<style>
  /*
  Vuetify defaults to breaking words in certain titles when wrapping to support IE11.
  Affected components: v-card-text | v-card-title | v-card
  This overrides that default, dropping support for IE11.
  Credit: https://github.com/vuetifyjs/vuetify/issues/9130#issuecomment-542534966
  */
  .v-card__text, .v-card__title {
    word-break: normal;
  }
</style>
