<template>
  <v-navigation-drawer
      v-model="navDrawer"
      app
      width="290"
  >

    <v-img
        class="pa-4 pt-7"
        height="170"
        src="@/assets/img/DSC_1772-1.jpg"
        gradient="to top right, rgba(227,216,200,.4), rgba(213,192,172,.8)"
    >
<!--      <v-avatar size="70" class="mb-2">-->
<!--        <img-->
<!--            src="https://cdn.vuetifyjs.com/images/john.jpg"-->
<!--            alt="John"-->
<!--        >-->
<!--      </v-avatar>-->
<!--      <div class="white&#45;&#45;text text-subtitle-1 font-weight-bold">Kevin & Helen</div>-->
<!--      <div class="white&#45;&#45;text text-subtitle-2">12th February 2020</div>-->
    </v-img>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          Your Love Story
        </v-list-item-title>
        <v-list-item-subtitle>

        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list
        dense
        nav
    >
      <v-list-item
          v-for="item in menuItems"
          :key="item.id"
          :to="item.to"
          link
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>
            {{ item.title }}
            <v-icon small color="green" v-if="completed(item.id)">mdi-check</v-icon>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>

export default {
  name: "NavDrawer",
  methods: {
    completed(id) {
      // Check if a page has been marked as complete.
      const page = this.$store.state.pages.find(page => page.id === id)
      if (page.complete !== null) {
        return !!page.complete
      } else {
        return false
      }
    },
  },
  computed: {
    menuItems() {
      let returnArray = [
        {id: 'order', title: 'Your Order', icon: 'mdi-basket', to: {name: 'Order'}},
        {id: 'home', title: 'Home', icon: 'mdi-home-circle', to: {name: 'Home'}},
        {id: 'gettingStarted', title: 'Getting Started', icon: 'mdi-playlist-edit', to: {name: 'GettingStarted'}},
        {id: 'theme', title: 'Theme', icon: 'mdi-image', to: {name: 'Theme'}},
        {id: 'frontCover', title: 'Front Cover', icon: 'mdi-book-open-page-variant', to: {name: 'Front'}},
        {id: 'insideFrontCover', title: 'Page 2 - Inside Front Cover', icon: 'mdi-playlist-edit', to: {name: 'Page2'}},
        {id: 'page3', title: 'Page 3 - Activity', icon: 'mdi-puzzle-check', to: {name: 'Page3'}},
        {id: 'page4', title: 'Page 4 - Activity', icon: 'mdi-puzzle-plus', to: {name: 'Page4'}},
        {id: 'page5', title: 'Page 5 - Activity', icon: 'mdi-puzzle-plus', to: {name: 'Page5'}},
        {id: 'page6-7', title: 'Page 6 & 7 - Centre Spread', icon: 'mdi-image', to: {name: 'Page6-7'}},
        {id: 'page8', title: 'Page 8 - Activity', icon: 'mdi-puzzle-plus', to: {name: 'Page8'}},
        {id: 'page9', title: 'Page 9 - Activity', icon: 'mdi-puzzle-plus', to: {name: 'Page9'}},
        {id: 'page10', title: 'Page 10 - Activity', icon: 'mdi-puzzle-plus', to: {name: 'Page10'}},
        {id: 'insideBackCover', title: 'Page 11 - Inside Back Cover', icon: 'mdi-playlist-edit', to: {name: 'Page11'}},
        {id: 'absentFriends', title: 'Absent Friends', icon: 'mdi-book-open-page-variant', to: {name: 'AbsentFriends'}},
        {id: 'backCover', title: 'Back Cover', icon: 'mdi-book-open-page-variant', to: {name: 'Back'}},
        {id: 'submit', title: 'Submit', icon: 'mdi-basket', to: {name: 'Submit'}},
        //{id: 'weddingWall', title: 'Wedding Wall', icon: 'mdi-wall', to: {name: 'WeddingWall'}},
      ]

      function removeMenuItem(id) {
        // Find menu item object by id.
        const menuItemObj = returnArray.find(obj => obj.id === id)
        // Get index of that object in array.
        const menuItemObjIndex = returnArray.indexOf(menuItemObj)
        // Remove object from array.
        returnArray.splice(menuItemObjIndex, 1)
      }

      // Custom Covers
      if (this.$store.state.customCovers) {
        // Remove `theme` if Custom Covers is not enabled.
        removeMenuItem("theme")
      } else {
        // Remove `frontCover` & `backCover` if Custom Covers is not enabled.
        removeMenuItem("frontCover")
        removeMenuItem("backCover")
      }

      // Remove `absentFriends` if Absent Friends is not enabled.
      if (!this.$store.state.absentFriends) {
        removeMenuItem("absentFriends")
      }

      return returnArray
    },
    navDrawer: {
      get() {
        return this.$store.state.navDrawer
      },
      set() {
        // Empty! This is only here suppress Vue's warning about defining a setter.
      }
    },
  }
}
</script>
