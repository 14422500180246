import Vue from 'vue'
import Vuex from 'vuex'
import {format, parseISO} from 'date-fns'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    order: {
      sourceOrderId: "",
      generalData: {
        name_1: "",
        name_2: "",
        wed_date: "",
        wed_address: "",
        pen_text: "",
      },
      pages: [
        {
          name: "cover",
          data: {
            design: "",
            img: "",
            img_fc: "",
            img_bc: "",
            fileName: "",
          }
        },
        {
          name: "p2",
          data: {
            img: "",
            fileName: "",
            wed_intro: "",
            abs_intro: "",
          }
        },
        {
          name: "p11",
          data: {
            img: "",
            fileName: "",
            caption: "",
            farewell: "",
            abs_intro: "",
            url: "",
            pin: "",
          },
        },
        {
          // This page exists for internal use only. Data stored here will be transformed on submit/preview.
          name: "backcover",
          data: {
            img: "",
            fileName: "",
          }
        },
      ],
      activities: [
        {
          name: "a",
          valid: false,
          data: {
            img: "",
            fileName: "",
            caption: "",
          }
        },
        {
          name: "b",
          valid: false,
          data: {
            img: "",
            fileName: "",
            caption: "",
            q1: "",
            a1: "",
            q2: "",
            a2: "",
            q3: "",
            a3: "",
            q4: "",
            a4: "",
            q5: "",
            a5: "",
            q6: "",
            a6: "",
            // This object is for internal use only for simpler interaction between the form and the Vuex state.
            // The data within will need to be copied into the relevant q/a properties alongside it on submission.
            questionsAndAnswers: [
              {
                question: "",
                answer: ""
              },
              {
                question: "",
                answer: ""
              },
              {
                question: "",
                answer: ""
              },
              {
                question: "",
                answer: ""
              },
              {
                question: "",
                answer: ""
              },
              {
                question: "",
                answer: ""
              },
            ],
          }
        },
        {
          name: "c",
          valid: true,
          data: {}
        },
        {
          name: "d",
          valid: false,
          data: {
            img: "",
            fileName: "",
            caption: "",
            message: ""
          }
        },
        {
          name: "e",
          valid: false,
          data: {
            img: "",
            fileName: "",
            caption: ""
          }
        },
        {
          name: "f",
          valid: false,
          data: {
            img: "",
            fileName: "",
            caption: "",
            limerick: ""
          }
        },
        {
          name: "g",
          valid: true,
          data: {},
        },
        {
          name: "h",
          valid: false,
          data: {
            img: "",
            fileName: "",
            caption: ""
          }
        },
        {
          name: "i",
          valid: true,
          data: {},
        },
        {
          name: "j",
          valid: false,
          data: {
            img: "",
            fileName: "",
            caption: "",
            q1: "",
            a1: "",
            q2: "",
            a2: "",
            q3: "",
            a3: "",
            q4: "",
            a4: "",
            q5: "",
            a5: "",
            q6: "",
            a6: "",
            // This object is for internal use only for simpler interaction between the form and the Vuex state.
            // The data within will need to be copied into the relevant q/a properties alongside it on submission.
            statements: {
              facts: [
                {
                  text: "",
                },
                {
                  text: "",
                },
                {
                  text: "",
                },
              ],
              falsehoods: [
                {
                  text: "",
                },
                {
                  text: "",
                },
                {
                  text: "",
                },
              ],
            },
          }
        },
        {
          name: "k",
          valid: false,
          data: {
            memory: ""
          }
        },
        {
          name: "l",
          valid: false,
          data: {
            img: "",
            fileName: "",
            caption: "",
            q1: "",
            a1: "",
            q2: "",
            a2: "",
            q3: "",
            a3: "",
            q4: "",
            a4: "",
            q5: "",
            a5: "",
            agb: "",
            // This object is for internal use only for simpler interaction between the form and the Vuex state.
            // The data within will need to be copied into the relevant q/a properties alongside it on submission.
            questionsAndAnswers: [
              {
                question: "",
                answer: ""
              },
              {
                question: "",
                answer: ""
              },
              {
                question: "",
                answer: ""
              },
              {
                question: "",
                answer: ""
              },
              {
                question: "",
                answer: ""
              },
            ],
          }
        },
        {
          name: "m",
          valid: false,
          data: {
            img: "",
            fileName: "",
            caption: "",
            anag1: "",
            a1: "",
            h1: "",
            chcount1: "",
            anag2: "",
            a2: "",
            h2: "",
            chcount2: "",
            anag3: "",
            a3: "",
            h3: "",
            chcount3: "",
            anag4: "",
            a4: "",
            h4: "",
            chcount4: "",
            anag5: "",
            a5: "",
            h5: "",
            chcount5: "",
            anag6: "",
            a6: "",
            h6: "",
            chcount6: "",
            // This object is for internal use only for simpler interaction between the form and the Vuex state.
            // The data within will need to be copied into the relevant anagram properties alongside it on submission.
            anagrams: [
              {
                answer: "",
                generated: "",
                charCount: "",
                hint: ""
              },
              {
                answer: "",
                generated: "",
                charCount: "",
                hint: ""
              },
              {
                answer: "",
                generated: "",
                charCount: "",
                hint: ""
              },
              {
                answer: "",
                generated: "",
                charCount: "",
                hint: ""
              },
              {
                answer: "",
                generated: "",
                charCount: "",
                hint: ""
              },
              {
                answer: "",
                generated: "",
                charCount: "",
                hint: ""
              },
            ],
          }
        }
      ]
    },
    // Non-order related centralised state can live here and won't be sent with the submitted JSON object.
    pageChallengeMap: {
      "3": "",
      "4": "",
      "5": "",
      "8": "",
      "9": "",
      "10": "",
      "absentFriendsH": "",
      "absentFriendsI": "",
    },
    absentFriends: false,
    customCovers: false,
    weddingDate: {},
    pureprintOrderId: "",
    dialogPreview: false,
    previewError: 0,
    previewImagesReady: false,
    previewImages: [],
    navDrawer: true,
    pages: [
      {
        id: "order",
        complete: null,
        route: 'Order',
        enabled: true,
      },
      {
        id: "home",
        complete: null,
        route: 'Home',
        enabled: true,
      },
      {
        id: "gettingStarted",
        complete: false,
        route: 'GettingStarted',
        enabled: true,
      },
      {
        id: "theme",
        complete: false,
        route: 'Theme',
        enabled: false,
      },
      {
        id: "frontCover",
        complete: false,
        route: 'Front',
        enabled: false,
      },
      {
        id: "insideFrontCover",
        bookletPageNum: "2",
        complete: false,
        route: 'Page2',
        enabled: true,
      },
      {
        id: "page3",
        bookletPageNum: "3",
        complete: false,
        route: 'Page3',
        enabled: true,
      },
      {
        id: "page4",
        bookletPageNum: "4",
        complete: false,
        route: 'Page4',
        enabled: true,
      },
      {
        id: "page5",
        bookletPageNum: "5",
        complete: false,
        route: 'Page5',
        enabled: true,
      },
      {
        id: "page6-7",
        bookletPageNum: "6-7",
        complete: true,
        route: 'Page6-7',
        enabled: true,
      },
      {
        id: "page8",
        bookletPageNum: "8",
        complete: false,
        route: 'Page8',
        enabled: true,
      },
      {
        id: "page9",
        bookletPageNum: "9",
        complete: false,
        route: 'Page9',
        enabled: true,
      },
      {
        id: "page10",
        bookletPageNum: "10",
        complete: false,
        route: 'Page10',
        enabled: true,
      },
      {
        id: "insideBackCover",
        bookletPageNum: "11",
        complete: false,
        route: 'Page11',
        enabled: true,
      },
      {
        id: "absentFriends",
        complete: false,
        route: 'AbsentFriends',
        enabled: false,
      },
      {
        id: "backCover",
        bookletPageNum: "12",
        complete: false,
        route: 'Back',
        enabled: false,
      },
      {
        id: "submit",
        complete: null,
        route: 'Submit',
        enabled: true,
      },
      {
        id: "weddingWall",
        complete: null,
        route: 'WeddingWall',
        enabled: true,
      },
    ],
    readOnlyMode: false,
    marketingData: {
      weddingVenue: "",
      referralSrc: "",
    },
    submittedOrder: {
      dateSubmitted: {},
      regular: {},
      absentFriends: {}
    },
    theme: "",
    weddingWall: {
      complete: false,
      title: "Wedding Wall",
      homeMediaType: "photos-activities", // "photos-activities" || "photo-uploaded" || "video-uploaded"
      homeUploadedImage: {
        filename: "",
        paths: {
          original: "",
          web: "",
          // webCropped: "",
        },
      },
      homeUploadedVideo: {
        filename: "",
        paths: {
          original: "",
          web: "",
          // webCropped: "",
        },
      },
      liveFeedEmbedCode: null
    },
    user: {
      isAdmin: false
    },
    specificOrderLoaded: false
  },
  getters: {
    getUploadedImgFileName: (state) => (sourceType, sourceId) => {
      let contentTypeObj
      if (sourceType === "page") {
        contentTypeObj = state.order.pages
      }
      if (sourceType === "challenge") {
        contentTypeObj = state.order.activities
      }
      const contentObj = contentTypeObj.find(o => o.name === sourceId)
      return contentObj.data.fileName
    },
    getUploadedImgPath: (state) => (sourceType, sourceId) => {
      let contentTypeObj
      if (sourceType === "page") {
        contentTypeObj = state.order.pages
      }
      if (sourceType === "challenge") {
        contentTypeObj = state.order.activities
      }
      const contentObj = contentTypeObj.find(o => o.name === sourceId)
      return contentObj.data.img
    },
    getWeddingDateFormatted: state => {
      if (state.weddingDate.seconds) {
        const dateObj = new Date(state.weddingDate.seconds * 1000);
        return format(parseISO(dateObj.toISOString()), 'dd.MM.yyyy')
      } else {
        return ""
      }
    }
  },
  mutations: {
    resetCmsDataDump(state) {
      state.dialogPreview = false
      state.marketingData = {
        weddingVenue: "",
        referralSrc: "",
      }
      state.navDrawer = true
      state.order = {
        sourceOrderId: "",
        generalData: {
          name_1: "",
          name_2: "",
          wed_date: "",
          wed_address: "",
          pen_text: "",
        },
        pages: [
          {
            name: "cover",
            data: {
              design: "",
              img: "",
              img_fc: "",
              img_bc: "",
              fileName: "",
            }
          },
          {
            name: "p2",
            data: {
              img: "",
              fileName: "",
              wed_intro: "",
              abs_intro: "",
            }
          },
          {
            name: "p11",
            data: {
              img: "",
              fileName: "",
              caption: "",
              farewell: "",
              abs_intro: "",
              url: "",
              pin: "",
            },
          },
          {
            // This page exists for internal use only. Data stored here will be transformed on submit/preview.
            name: "backcover",
            data: {
              img: "",
              fileName: "",
            }
          },
        ],
        activities: [
          {
            name: "a",
            valid: false,
            data: {
              img: "",
              fileName: "",
              caption: "",
            }
          },
          {
            name: "b",
            valid: false,
            data: {
              img: "",
              fileName: "",
              caption: "",
              q1: "",
              a1: "",
              q2: "",
              a2: "",
              q3: "",
              a3: "",
              q4: "",
              a4: "",
              q5: "",
              a5: "",
              q6: "",
              a6: "",
              // This object is for internal use only for simpler interaction between the form and the Vuex state.
              // The data within will need to be copied into the relevant q/a properties alongside it on submission.
              questionsAndAnswers: [
                {
                  question: "",
                  answer: ""
                },
                {
                  question: "",
                  answer: ""
                },
                {
                  question: "",
                  answer: ""
                },
                {
                  question: "",
                  answer: ""
                },
                {
                  question: "",
                  answer: ""
                },
                {
                  question: "",
                  answer: ""
                },
              ],
            }
          },
          {
            name: "c",
            valid: true,
            data: {}
          },
          {
            name: "d",
            valid: false,
            data: {
              img: "",
              fileName: "",
              caption: "",
              message: ""
            }
          },
          {
            name: "e",
            valid: false,
            data: {
              img: "",
              fileName: "",
              caption: ""
            }
          },
          {
            name: "f",
            valid: false,
            data: {
              img: "",
              fileName: "",
              caption: "",
              limerick: ""
            }
          },
          {
            name: "g",
            valid: true,
            data: {},
          },
          {
            name: "h",
            valid: false,
            data: {
              img: "",
              fileName: "",
              caption: ""
            }
          },
          {
            name: "i",
            valid: true,
            data: {},
          },
          {
            name: "j",
            valid: false,
            data: {
              img: "",
              fileName: "",
              caption: "",
              q1: "",
              a1: "",
              q2: "",
              a2: "",
              q3: "",
              a3: "",
              q4: "",
              a4: "",
              q5: "",
              a5: "",
              q6: "",
              a6: "",
              // This object is for internal use only for simpler interaction between the form and the Vuex state.
              // The data within will need to be copied into the relevant q/a properties alongside it on submission.
              statements: {
                facts: [
                  {
                    text: "",
                  },
                  {
                    text: "",
                  },
                  {
                    text: "",
                  },
                ],
                falsehoods: [
                  {
                    text: "",
                  },
                  {
                    text: "",
                  },
                  {
                    text: "",
                  },
                ],
              },
            }
          },
          {
            name: "k",
            valid: false,
            data: {
              memory: ""
            }
          },
          {
            name: "l",
            valid: false,
            data: {
              img: "",
              fileName: "",
              caption: "",
              q1: "",
              a1: "",
              q2: "",
              a2: "",
              q3: "",
              a3: "",
              q4: "",
              a4: "",
              q5: "",
              a5: "",
              agb: "",
              // This object is for internal use only for simpler interaction between the form and the Vuex state.
              // The data within will need to be copied into the relevant q/a properties alongside it on submission.
              questionsAndAnswers: [
                {
                  question: "",
                  answer: ""
                },
                {
                  question: "",
                  answer: ""
                },
                {
                  question: "",
                  answer: ""
                },
                {
                  question: "",
                  answer: ""
                },
                {
                  question: "",
                  answer: ""
                },
              ],
            }
          },
          {
            name: "m",
            valid: false,
            data: {
              img: "",
              fileName: "",
              caption: "",
              anag1: "",
              a1: "",
              h1: "",
              chcount1: "",
              anag2: "",
              a2: "",
              h2: "",
              chcount2: "",
              anag3: "",
              a3: "",
              h3: "",
              chcount3: "",
              anag4: "",
              a4: "",
              h4: "",
              chcount4: "",
              anag5: "",
              a5: "",
              h5: "",
              chcount5: "",
              anag6: "",
              a6: "",
              h6: "",
              chcount6: "",
              // This object is for internal use only for simpler interaction between the form and the Vuex state.
              // The data within will need to be copied into the relevant anagram properties alongside it on submission.
              anagrams: [
                {
                  answer: "",
                  generated: "",
                  charCount: "",
                  hint: ""
                },
                {
                  answer: "",
                  generated: "",
                  charCount: "",
                  hint: ""
                },
                {
                  answer: "",
                  generated: "",
                  charCount: "",
                  hint: ""
                },
                {
                  answer: "",
                  generated: "",
                  charCount: "",
                  hint: ""
                },
                {
                  answer: "",
                  generated: "",
                  charCount: "",
                  hint: ""
                },
                {
                  answer: "",
                  generated: "",
                  charCount: "",
                  hint: ""
                },
              ],
            }
          }
        ]
      }
      state.pageChallengeMap = {
        "3": "",
        "4": "",
        "5": "",
        "8": "",
        "9": "",
        "10": "",
        "absentFriendsH": "",
        "absentFriendsI": "",
      }
      state.pages = [
        {
          id: "order",
          complete: null,
          route: 'Order',
          enabled: true,
        },
        {
          id: "home",
          complete: null,
          route: 'Home',
          enabled: true,
        },
        {
          id: "gettingStarted",
          complete: false,
          route: 'GettingStarted',
          enabled: true,
        },
        {
          id: "theme",
          complete: false,
          route: 'Theme',
          enabled: false,
        },
        {
          id: "frontCover",
          complete: false,
          route: 'Front',
          enabled: false,
        },
        {
          id: "insideFrontCover",
          bookletPageNum: "2",
          complete: false,
          route: 'Page2',
          enabled: true,
        },
        {
          id: "page3",
          bookletPageNum: "3",
          complete: false,
          route: 'Page3',
          enabled: true,
        },
        {
          id: "page4",
          bookletPageNum: "4",
          complete: false,
          route: 'Page4',
          enabled: true,
        },
        {
          id: "page5",
          bookletPageNum: "5",
          complete: false,
          route: 'Page5',
          enabled: true,
        },
        {
          id: "page6-7",
          bookletPageNum: "6-7",
          complete: true,
          route: 'Page6-7',
          enabled: true,
        },
        {
          id: "page8",
          bookletPageNum: "8",
          complete: false,
          route: 'Page8',
          enabled: true,
        },
        {
          id: "page9",
          bookletPageNum: "9",
          complete: false,
          route: 'Page9',
          enabled: true,
        },
        {
          id: "page10",
          bookletPageNum: "10",
          complete: false,
          route: 'Page10',
          enabled: true,
        },
        {
          id: "insideBackCover",
          bookletPageNum: "11",
          complete: false,
          route: 'Page11',
          enabled: true,
        },
        {
          id: "absentFriends",
          complete: false,
          route: 'AbsentFriends',
          enabled: false,
        },
        {
          id: "backCover",
          bookletPageNum: "12",
          complete: false,
          route: 'Back',
          enabled: false,
        },
        {
          id: "submit",
          complete: null,
          route: 'Submit',
          enabled: true,
        },
        {
          id: "weddingWall",
          complete: null,
          route: 'WeddingWall',
          enabled: true,
        },
      ]
      state.previewError = 0
      state.previewImages = []
      state.previewImagesReady = false
      state.readOnlyMode = false
      state.submittedOrder = {
        dateSubmitted: {},
        regular: {},
        absentFriends: {}
      }
      state.theme = ""
      state.weddingWall = {
        complete: false,
        title: "Wedding Wall",
        homeMediaType: "photos-activities", // "photos-activities" || "photo-uploaded" || "video-uploaded"
        homeUploadedImage: {
          filename: "",
          paths: {
            original: "",
            web: "",
            // webCropped: "",
          },
        },
        homeUploadedVideo: {
          filename: "",
          paths: {
            original: "",
            web: "",
            // webCropped: "",
          },
        },
        liveFeedEmbedCode: null
      }
    },
    setOrderDateSubmitted(state) {
      state.submittedOrder.dateSubmitted = new Date()
    },
    setSpecificOrderLoaded(state, payload) {
      state.specificOrderLoaded = payload
    },
    setUser(state, payload) {
      state.user.isAdmin = payload.isAdmin
    },
    setWeddingWallPageComplete(state, payload) {
      state.weddingWall.complete = payload
    },
    setWeddingWallPin(state, payload) {
      if (typeof payload !== "undefined") {
        state.order.pages[2].data.pin = payload
      }
    },
    setWeddingWallSlug(state, payload) {
      if (typeof payload !== "undefined") {
        state.order.pages[2].data.url = `https://ourwedwall.com/${payload}`
      }
    },
    setWeddingWallTitle(state, payload) {
      state.weddingWall.title = payload
    },
    setWeddingWallHomeMediaType(state, payload) {
      state.weddingWall.homeMediaType = payload
    },
    setWeddingWallUploadedImageFilename(state, payload) {
      state.weddingWall.homeUploadedImage.filename = payload
    },
    setWeddingWallUploadedImagePath(state, payload) {
      state.weddingWall.homeUploadedImage.paths.original = payload
    },
    setWeddingWallLiveFeedEmbedCode(state, payload) {
      state.weddingWall.liveFeedEmbedCode = payload
    },
    setAbsentFriends(state, payload) {
      state.absentFriends = payload
      state.pages.find(page => page.id === "absentFriends").enabled = payload
    },
    setCustomCovers(state, payload) {
      state.customCovers = payload
      if (payload === true) {
        state.order.pages[0].data.design = "Custom Covers"
      } else {
        state.order.pages[0].data.design = ""
      }
      state.pages.find(page => page.id === "theme").enabled = !payload
      state.pages.find(page => page.id === "frontCover").enabled = payload
      state.pages.find(page => page.id === "backCover").enabled = payload
    },
    setDialogPreview(state, payload) {
      state.dialogPreview = payload
    },
    setPreviewError(state, payload) {
      state.previewError = payload
    },
    setPreviewImages(state, payload) {
      // Init array.
      state.previewImages = []
      // Walk through payload and append cache busting query string to each image path
      // before pushing to state.previewImages.
      payload.forEach(image => {
        // Random string consisting of 8 numbers.
        const cacheBuster = Math.random().toString().substr(2, 8)
        state.previewImages.push(`${image}&CacheBust=${cacheBuster}`)
      })
    },
    setPreviewImagesReady(state, payload) {
      state.previewImagesReady = payload
    },
    setReadOnlyMode(state, payload) {
      state.readOnlyMode = payload
    },
    setTheme(state, payload) {
      state.theme = payload
    },
    mapChallengeToPage(state, payload) {
      state.pageChallengeMap[payload.pageNumInternal] = payload.challengeId
    },
    setActivity(state, payload) {
      // Find correct activity object by ID (name property).
      const activityObj = state.order.activities.find(o => o.name === payload.challengeId)
      if (typeof payload.caption != "undefined") {
        activityObj.data.caption = payload.caption
      }
      if (typeof payload.imgPath != "undefined") {
        activityObj.data.img = payload.imgPath
      }
      if (typeof payload.message != "undefined") {
        activityObj.data.message = payload.message
      }
      if (typeof payload.limerick != "undefined") {
        activityObj.data.limerick = payload.limerick
      }
      if (typeof payload.memory != "undefined") {
        activityObj.data.memory = payload.memory
      }
      if (typeof payload.questionsAndAnswers != "undefined") {
        activityObj.data.questionsAndAnswers = payload.questionsAndAnswers
      }
      if (typeof payload.anagrams != "undefined") {
        activityObj.data.anagrams = payload.anagrams
      }
      if (typeof payload.valid != "undefined") {
        activityObj.valid = payload.valid
      }
      // Challenge J.
      if (typeof payload.facts != "undefined") {
        activityObj.data.statements.facts = payload.facts
      }
      if (typeof payload.falsehoods != "undefined") {
        activityObj.data.statements.falsehoods = payload.falsehoods
      }
      // Challenge L.
      if (typeof payload.redHerring != "undefined") {
        activityObj.data.agb = payload.redHerring
      }
    },
    setMarketingData(state, payload) {
      if (typeof payload.weddingVenue != "undefined") {
        state.marketingData.weddingVenue = payload.weddingVenue
      }
      if (typeof payload.referralSrc != "undefined") {
        state.marketingData.referralSrc = payload.referralSrc
      }
    },
    setOrderGDName1(state, payload) {
      state.order.generalData.name_1 = payload
    },
    setOrderGDName2(state, payload) {
      state.order.generalData.name_2 = payload
    },
    setWeddingDate(state, payload) {
      state.weddingDate = payload
    },
    setOrderGDWedAddress(state, payload) {
      state.order.generalData.wed_address = payload
    },
    setOrderGDPenText(state, payload) {
        state.order.generalData.pen_text = payload
      },
    setP2WedIntro(state, payload) {
      // TODO: This is horribly fragile, find object by searching for "p2" key instead.
      state.order.pages[1].data.wed_intro = payload
    },
    setP2AbsIntro(state, payload) {
      // TODO: This is horribly fragile, find object by searching for "p2" key instead.
      state.order.pages[1].data.abs_intro = payload
    },
    setP11farewell(state, payload) {
      // TODO: This is horribly fragile, find object by searching for "p2" key instead.
      state.order.pages[2].data.farewell = payload
    },
    setP11caption(state, payload) {
        // TODO: This is horribly fragile, find object by searching for "p2" key instead.
        state.order.pages[2].data.caption = payload
      },
    setP11AbsFarewell(state, payload) {
      // TODO: This is horribly fragile, find object by searching for "p2" key instead.
      // TODO: Really not sure about this key for page 11 (abs_intro).
      state.order.pages[2].data.abs_intro = payload
    },
    setSubmittedOrder(state, payload) {
      state.submittedOrder.regular = payload
    },
    setSubmittedOrderAf(state, payload) {
      state.submittedOrder.absentFriends = payload
    },
    setUploadedImgFileName(state, payload) {
      let contentTypeObj
      if (payload.sourceType === "page") {
        contentTypeObj = state.order.pages
      }
      if (payload.sourceType === "challenge") {
        contentTypeObj = state.order.activities
      }
      const contentObj = contentTypeObj.find(o => o.name === payload.sourceId)
      contentObj.data.fileName = payload.fileName
    },
    setUploadedImgPath(state, payload) {
      let contentTypeObj
      if (payload.sourceType === "page") {
        contentTypeObj = state.order.pages
      }
      if (payload.sourceType === "challenge") {
        contentTypeObj = state.order.activities
      }
      const contentObj = contentTypeObj.find(o => o.name === payload.sourceId)
      contentObj.data.img = payload.imgPath
    },
    setSourceOrderID(state, payload) {
      state.order.sourceOrderId = payload
    },
    setAppState(state, payload) {
      state.marketingData = payload.marketingData
      state.order = payload.order
      state.pageChallengeMap = payload.pageChallengeMap
      state.pages = payload.pages
      state.readOnlyMode= payload.readOnlyMode
      state.submittedOrder = payload.submittedOrder
      state.theme = payload.theme
      state.weddingWall = payload.weddingWall
    },
    setPurePrintOrderId(state, payload) {
      state.pureprintOrderId = payload
    },
    toggleNavDrawer(state) {
      state.navDrawer = !state.navDrawer
    },
  },
  actions: {
  },
  modules: {
  }
})
