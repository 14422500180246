var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"app":"","color":"#d5c0ac","dark":"","shrink-on-scroll":"","height":"170","src":require("@/assets/img/20201006-DSC_2098-1.jpg"),"prominent":"","extension-height":"25"},scopedSlots:_vm._u([{key:"img",fn:function(ref){
var props = ref.props;
return [_c('v-img',_vm._b({attrs:{"gradient":"to top right, rgba(227,216,200,.4), rgba(213,192,172,.8)"}},'v-img',props,false))]}},{key:"extension",fn:function(){return [_c('v-progress-linear',{attrs:{"absolute":"","value":_vm.completionProgress,"color":"green","height":"25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_c('span',{staticClass:"overline"},[_vm._v(_vm._s(Math.ceil(value))+"% complete")])]}}])})]},proxy:true}])},[_c('v-app-bar-nav-icon',{on:{"click":_vm.toggleNavDrawer}},[_c('div',{staticClass:"custom-hamburger"},[_c('v-icon',{attrs:{"color":"#d5c0ac"}},[_vm._v("mdi-menu")])],1)]),_c('v-toolbar-title',[_c('h6',[_vm._v("CherryTop Weddings")])]),_c('v-spacer'),_c('v-btn',{staticClass:"mt-1",attrs:{"to":{name: 'Help'},"color":"secondary"}},[_vm._v(" Help & FAQ ")]),_c('v-menu',{attrs:{"left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-title',[_vm._v("Logout")])],1),(_vm.$store.state.user.isAdmin)?_c('v-list-item',{on:{"click":_vm.resetDocumentInFirestore}},[_c('v-list-item-title',[_vm._v("Reset CMS data in Firebase")])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }