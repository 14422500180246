<template>
  <v-container>
    <v-row class="pa-2" justify="center">
      <v-btn
          class="mx-4" 
          color="#d5c0ac"
          :to="prevRoute"
          v-if="showPrevBtn"
      >
        <v-icon left>mdi-chevron-left</v-icon>
        Previous
      </v-btn>
     
      <v-btn
          class="mx-4" 
          color="#d5c0ac"      
          :to="nextRoute"
          v-if="showNextBtn"
      >
        Next
        <v-icon right>mdi-chevron-right</v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "NavPrevNext",
  computed: {
    pagesFiltered() {
      return this.$store.state.pages.filter(page => page.enabled)
    },
    currentRouteIndex() {
      return this.pagesFiltered.findIndex(page => page.route === this.$route.name)
    },
    showNextBtn() {
      // Temp fix to hide nav to WeddingWall page for now.
      if (this.nextRoute.name === "WeddingWall") {
        return false
      }
      return this.$route.name !== this.pagesFiltered[this.pagesFiltered.length - 1].route
    },
    showPrevBtn() {
      return this.$route.name !== this.pagesFiltered[0].route
    },
    nextRoute() {
      if (this.currentRouteIndex < (this.pagesFiltered.length - 1)) {
        const nextPage = this.pagesFiltered[this.currentRouteIndex + 1]
        return {name: nextPage.route}
      }
    },
    prevRoute() {
      if (this.currentRouteIndex > 0) {
        const prevPage = this.pagesFiltered[this.currentRouteIndex - 1]
        return {name: prevPage.route}
      }
    },
  },
  methods: {

  },
}
</script>
